<template>
  <b-dropdown
    class="mt-1"
    text="Selecionar"
    variant="relief-primary"
  >
    <b-dropdown-item
      v-if="selectionMode !== null"
      @click.stop="$emit('update:selectionMode', null)"
    >
      Cancelar seleção (ESC)
    </b-dropdown-item>
    <b-dropdown-item @click.stop="$emit('update:selectionMode', 'multi')">
      Seleção com Clique
    </b-dropdown-item>
    <b-dropdown-item
      @click.stop="$emit('update:selectionMode', 'range')"
    >Seleção com Teclado e Mouse</b-dropdown-item>
    <b-dropdown-item @click.stop="selectAll()">
      Selecionar Tudo
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue'

export default {
  components: {
    BDropdown,
    BDropdownItem
  },
  model: {
    prop: 'selectionMode',
    event: 'update:selectionMode'
  },
  props: {
    selectionMode: {
      default: null,
      required: false
    }
  },
  mounted () {
    window.addEventListener('keyup', e => {
      if (e.key === 'Escape') {
        this.$emit('clear')
        this.$emit('update:selectionMode', null)
      }
    })
  },
  methods: {
    selectAll () {
      this.$emit('update:selectionMode', 'multi')

      setTimeout(() => {
        this.$emit('selectAll', true)
      }, 500)
    }
  }
}
</script>
