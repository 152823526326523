<template>
  <section>
    <b-overlay
      id="overlay-background"
      :show="loading"
      variant="transparent"
      opacity="1"
      blur="2px"
      rounded="sm"
    >
      <b-alert
        variant="primary"
        show
      >
        <h4 class="alert-heading">
          <feather-icon
            icon="MailIcon"
            size="12"
          /> &nbsp; Atenção!
        </h4>
        <div class="alert-body">
          Se você comprou Contas de E-mail, o acesso é por aqui: <br> <br>
          <b-button
            variant="relief-primary"
            @click.stop="openMail()"
          >
            Clique aqui para acessar a caixa de email.
          </b-button> <br> <br>
          <span>Se você comprou alguma conta do Instagram ou Tiktok, e ela possui e-mail. Clique em Modo Completo (precisa ficar verde) que irá aparecer um campo de e-mail, basta clicar que irá abrir a caixa de e-mail.</span>
        </div>
      </b-alert>
      <b-card>
        <b-card-body v-if="!fullMode">
          <b-card-title>
            Minhas Contas
          </b-card-title>
        </b-card-body>
        <b-row>
          <b-col
            md="3"
            sm="4"
            class="my-1"
          >
            <b-form-group class="mb-0">
              <label
                class="d-inline-block text-sm-left mr-50"
              >Por Página</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-25"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="5"
            sm="8"
            class="my-1"
          >
            <b-form-group
              label="Ordenar Por"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="sortBySelect"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                  class="w-75"
                >
                  <template v-slot:first>
                    <option value="">
                      -- Nenhum --
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                  class="w-25"
                >
                  <option :value="false">
                    Asc
                  </option>
                  <option :value="true">
                    Desc
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            md="4"
            class="my-1"
          >
            <b-form-group
              label="Buscar"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Escreva o que deseja pesquisar."
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Limpar
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mb-1"
          >
            <table-selection-mode
              v-model="selectionMode"
              class="mr-1"
              @selectAll="selectAllRows"
            />

            <table-export
              :items="items"
              :selected="selected"
              class="mr-1"
            />

            <table-hidden-selected
              v-if="selected.length > 0"
              :items="items"
              :selected="selected"
              :accounts="accounts"
              class="mr-1"
              @updateAccounts="accounts = $event"
              @updateItems="items = $event"
              @clear="clearSelected"
            />

            <table-mode
              v-model="tableMode"
              :accounts="accounts"
              class="mr-1"
              @update="items = $event"
              @fields="fields = $event"
            />

            <table-filter
              v-show="selected.length === 0"
              :accounts="accounts"
              class="mr-1"
              @update="items = $event"
            />

            <full-mode
              v-model="fullMode"
              class="mr-1"
            />
          </b-col>

          <b-col cols="12">
            <b-table
              v-if="showTable"
              ref="selectableTable"
              striped
              hover
              small
              :sticky-header="isMobile"
              no-border-collapse
              selectable
              :select-mode="selectionMode"
              :per-page="perPage"
              :current-page="currentPage"
              :items="items"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              @row-selected="onRowSelected"
            >
              <template #cell(pos)="data">
                {{ data.item.pos }}
              </template>

              <template #cell(username)="data">
                <b-button
                  v-clipboard:copy="data.item.username"
                  v-clipboard:success="onCopy"
                  size="sm"
                  variant="relief-primary"
                >
                  {{ data.item.username }}
                </b-button>
              </template>

              <template #cell(password)="data">
                <b-button
                  v-clipboard:copy="data.item.password"
                  v-clipboard:success="onCopy"
                  size="sm"
                  variant="relief-primary"
                >
                  {{ data.item.password }}
                </b-button>
              </template>

              <template #cell(email)="data">
                <b-button
                  v-if="data.item.email !== null"
                  v-clipboard:copy="data.item.email"
                  v-clipboard:success="onCopy"
                  size="sm"
                  variant="relief-primary"
                >
                  {{ data.item.email }}
                </b-button>
              </template>

              <template #cell(email_password)="data">
                <b-button
                  v-if="data.item.email_password !== null"
                  v-clipboard:copy="data.item.email_password"
                  v-clipboard:success="onCopy"
                  size="sm"
                  variant="relief-primary"
                >
                  {{ data.item.email_password }}
                </b-button>
              </template>

              <template #cell(mailbutton)="data">
                <b-button
                  v-if="data.item.email !== null"
                  size="sm"
                  variant="relief-primary"
                  @click.stop="$router.push('/emails/' + data.item.email)"
                >
                  <feather-icon
                    icon="MailIcon"
                    size="12"
                  />
                </b-button>
              </template>

              <template #cell(actions)="data">
                <b-button
                  size="sm"
                  variant="relief-primary"
                  @click="data.toggleDetails"
                >
                  Detalhes
                </b-button>
              </template>

              <template #row-details="data">
                <b-card>
                  <span class="font-weight-bolder">Tipo de conta: </span> <span v-text="data.item.option" /> <br>
                  <span class="font-weight-bolder">Usuário: </span> <span v-text="data.item.username" /> <br>
                  <span class="font-weight-bolder">Senha: </span> <span v-text="data.item.password" /> <br>
                  <span class="font-weight-bolder">Email: </span> <span v-text="data.item.email" /> <br>
                  <span class="font-weight-bolder">Senha do Email: </span> <span v-text="data.item.email_password" /> <br>
                  <span class="font-weight-bolder">Data de compra: </span> <span>{{ data.item.created_at | date }}</span> <br>
                  <span class="font-weight-bolder">Conta: </span> <span>{{ data.item.status }}</span> <br>
                </b-card>
              </template>
            </b-table>
          </b-col>

          <b-col cols="12">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              class="my-0"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </section>
</template>

<script>
import {
  BOverlay,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardTitle,
  BTable,
  BButton,
  BAlert
} from 'bootstrap-vue'
import IndexMixin from './mixins/IndexMixin'
import TableMixin from './mixins/TableMixin'
import TableMode from './Functions/TableMode.vue'
import FullMode from './Functions/FullMode.vue'
import TableSelectionMode from './Functions/TableSelectionMode.vue'
import TableHiddenSelected from './Functions/TableHiddenSelected.vue'
import TableExport from './Functions/TableExport.vue'
import TableFilter from './Functions/TableFilter.vue'

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardTitle,
    BTable,
    BButton,
    BAlert,
    TableMode,
    FullMode,
    TableSelectionMode,
    TableHiddenSelected,
    TableExport,
    TableFilter
  },
  mixins: [IndexMixin, TableMixin],
  computed: {
    isMobile () {
      return window.innerWidth < 800
    }
  },
  methods: {
    openMail () {
      window.open('https://webmail.contasinfinity.com.br/')
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
