import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'

export default {
  data: () => ({
    loading: true,
    fields: [],
    accounts: [],
    items: [],
    fullMode: false,
    tableMode: false,
    selectionMode: null
  }),
  filters: {
    date (val) {
      return moment(val).format('DD/MM/Y HH:mm:ss')
    }
  },
  watch: {
    selectionMode () {
      this.showTable = false
      this.clearSelected()

      setTimeout(() => {
        this.showTable = true
      }, 100)
    }
  },
  methods: {
    async getAccounts () {
      const url = '/user/services/my_purchases/sn_accounts'
      await this.$http.get(url)
        .then(res => {
          this.accounts = res.data.map((item, index) => {
            return {
              ...item,
              pos: index + 1
            }
          })

          this.items = this.accounts.filter(item => item.hidden === 0)
        })
        .finally(() => {
          this.loading = false
        })
    },
    onCopy () {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Texto copiado com sucesso!',
          icon: 'CheckCircleIcon',
          variant: 'success'
        }
      })
    }
  },
  async mounted () {
    await this.getAccounts()
  }
}
