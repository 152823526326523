<template>
  <div class="d-inline">
    <b-button
      class="mt-1"
      :variant="active ? 'relief-success' : 'relief-primary'"
      @click.stop="openFilterModal()"
    >
      <span>Filtrar</span>&nbsp;
      <feather-icon icon="FilterIcon" />
    </b-button>

    <b-modal
      v-model="visible"
      title="Filtrar contas"
      centered
    >
      <!-- Modal Body -->
      <section>
        <b-row>
          <!-- <b-col cols="12">
            <b-form-checkbox
              v-model="showHiddenItems"
            >
              Exibir contas ocultas
            </b-form-checkbox>
          </b-col>

          <b-col cols="12">
            <b-form-checkbox
              v-model="showOnlyHiddenItems"
            >
              Exibir somente contas ocultas
            </b-form-checkbox>
          </b-col> -->

          <b-col
            cols="12"
            class="mb-1"
          >
            <b-form-select
              v-model="selectedType"
              :state="selectedType === null ? false : true"
              :options="optionsType"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                >Selecione um tipo de conta.</b-form-select-option>
              </template>
            </b-form-select>
          </b-col>

          <b-col cols="12">
            <b-form-select
              v-model="selected"
              :state="selected === null ? false : true"
              :options="options"
            />
          </b-col>
        </b-row>
      </section>

      <!-- Modal Footer -->
      <template #modal-footer="{ hide }">
        <b-button
          size="sm"
          variant="relief-primary"
          @click.stop="hide"
        >
          Fechar
        </b-button>

        <b-button
          size="sm"
          variant="relief-success"
          @click.stop="filter()"
        >
          Filtrar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  BRow,
  BCol,
  BFormSelect,
  BFormSelectOption
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BModal,
    BRow,
    BCol,
    BFormSelect,
    BFormSelectOption
  },
  props: {
    accounts: {
      type: Array,
      required: true,
      default: []
    }
  },
  data: () => ({
    active: false,
    visible: false,
    selectedType: null,
    optionsType: [],
    selected: 1,
    options: [
      {
        value: 1,
        text: 'Exibir todas as contas (exceto ocultos)'
      },
      {
        value: 2,
        text: 'Exibir todas as contas (com ocultos)'
      },
      {
        value: 3,
        text: 'Exibir somente contas ocultas'
      }
    ]
  }),
  mounted () {
    this.$http.get('/user/services/options/sn_accounts')
      .then(res => {
        this.optionsType = res.data.map(item => {
          return {
            value: item.name,
            text: item.name
          }
        })
      })
  },
  methods: {
    openFilterModal () {
      this.visible = !this.visible
    },
    filter () {
      this.active = true
      let items = this.accounts

      if (this.selectedType !== null) {
        items = items.filter(item => item.option === this.selectedType)
      }

      switch (this.selected) {
      case 1:
        items = items.filter(item => item.hidden === 0)
        break

      case 3:
        items = items.filter(item => item.hidden === 1)
        break

      case 2:
      default:
        items = this.accounts
      }

      this.openFilterModal()

      this.$emit('update', items)
    }
  }
}
</script>
