<template>
  <b-dropdown
    class="mt-1"
    :text="selected.length > 0 ? `Exportar [${selected.length}]` : 'Exportar'"
    variant="relief-danger"
  >
    <b-dropdown-item @click.stop="minimalExport ()">
      Exportação Simples
    </b-dropdown-item>

    <b-dropdown-item @click.stop="fullExport ()">
      Exportação Completa
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import FuncTableExport from './mixins/FuncTableExport'

export default {
  components: {
    BDropdown,
    BDropdownItem
  },
  mixins: [FuncTableExport],
  props: {
    selected: {
      default: [],
      required: true
    },
    items: {
      default: [],
      required: true
    }
  }
}
</script>
