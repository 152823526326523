export default {
  methods: {
    hidden () {
      const itemsID = this.selected.map(item => item.id)
      const accounts = this.updateAccounts(itemsID)
      const items = this.items.filter(item => !itemsID.includes(item.id))
      this.$emit('updateAccounts', accounts)
      this.$emit('updateItems', items)

      this.applyHidden(itemsID)
    },
    async applyHidden (id) {
      const url = '/user/services/my_purchases/sn_accounts/update_hidden'
      const params = {
        id
      }

      await this.$http
        .post(url, params)
    },
    updateAccounts (payload) {
      const accounts = this.accounts

      for (const [index, item] of accounts.entries()) {
        if (payload.includes(item.id)) {
          accounts[index].hidden = accounts[index].hidden === 0 ? 1 : 0
        }
      }

      return accounts
    }
  }
}
