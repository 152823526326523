<template>
  <b-button
    class="mt-1"
    :variant="tableMode ? 'relief-success' : 'relief-warning'"
    @click.stop="updateTableMode()"
  >
    <span v-if="tableMode">Modo Completo</span>
    <span v-else>Modo Simples</span>
  </b-button>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import FuncTableModeMixin from './mixins/FuncTableModeMixin'

export default {
  components: {
    BButton
  },
  mixins: [FuncTableModeMixin],
  model: {
    prop: 'tableMode',
    event: 'update:tableMode'
  },
  props: {
    tableMode: {
      type: Boolean,
      default: false,
      required: true
    }
  }
}
</script>
