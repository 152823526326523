<template>
  <b-button
    class="mt-1"
    variant="relief-danger"
    @click.stop="hidden()"
  >
    Mostrar/Ocultar [{{ selected.length }}]
  </b-button>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import FuncTableHiddenSelectedMixin from './mixins/FuncTableHiddenSelectedMixin'

export default {
  components: {
    BButton
  },
  mixins: [FuncTableHiddenSelectedMixin],
  props: {
    selected: {
      type: Array,
      required: true,
      default: []
    },
    items: {
      type: Array,
      required: true,
      default: []
    },
    accounts: {
      type: Array,
      required: true,
      default: []
    }
  }
}
</script>
