export default {
  data: () => ({
    tableMode_local: false
  }),
  methods: {
    updateTableMode () {
      this.tableMode_local = !this.tableMode_local
      this.$emit('update:tableMode', this.tableMode_local)
      localStorage.setItem('_accounts_table_mode', this.tableMode_local)
      this.autoSetTable()
    },
    autoSetTable () {
      if (this.tableMode_local === true) {
        this.setTableFull()
      }

      if (this.tableMode_local === false) {
        this.setTableMinimal()
      }
    },
    setTableMinimal () {
      this.$emit('fields', [
        {
          key: 'username',
          label: 'Usuário',
          sortable: true
        },
        {
          key: 'password',
          label: 'senha',
          sortable: true
        },
        {
          key: 'actions',
          label: 'Ações'
        }
      ])
    },
    setTableFull () {
      this.$emit('fields', [
        {
          key: 'pos',
          label: 'id',
          sortable: true
        },
        {
          key: 'username',
          label: 'Usuário',
          sortable: true
        },
        {
          key: 'password',
          label: 'senha',
          sortable: true
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true
        },
        {
          key: 'email_password',
          label: 'Senha Email',
          sortable: true
        },
        {
          key: 'mailbutton',
          label: 'Email'
        },
        {
          key: 'option',
          label: 'Tipo de conta'
        }
      ])
    }
  },
  mounted () {
    if (localStorage.getItem('_accounts_table_mode') === null) {
      localStorage.setItem('_accounts_table_mode', false)
    }

    this.tableMode_local = JSON.parse(localStorage.getItem('_accounts_table_mode'))
    this.$emit('update:tableMode', this.tableMode_local)
    this.autoSetTable()
  }
}
